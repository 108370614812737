
import React, { useState, useEffect } from 'react';
import Dropdown from '../common/DropDown';
import { useDropdownWithREST } from './RESTProvider';

interface ItemType {
  id: number;
  name: string;
}

interface DropdownOption {
  key: string;
  label: string;
}

interface DropdownWithRESTProps {
  onOptionChange: (key: string|null) => void;
  selectedOptionKey: string | null;
  labelField?: string;
  buttonClassName?: string;
  dropdownClassName?: string;
  itemClassName?: string;
  itemHoverClassName?: string;
  itemSelectedClassName?: string;
  includeNullOption?: boolean; 
}

const DropdownWithREST: React.FC<DropdownWithRESTProps> = ({
  onOptionChange,
  selectedOptionKey,
  labelField = 'name',
  buttonClassName = "",
  dropdownClassName= "",
  itemClassName= "",
  itemHoverClassName= "",
  itemSelectedClassName= "",
  includeNullOption = false,
}) => {
  const { items } = useDropdownWithREST();
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    const formattedOptions = [
      ...(includeNullOption ? [{ key: null, label: '없음' }] : []), 
      ...items.map((item) => ({
        key: item.id.toString(),
        label: item[labelField] || `Item ${item.id}`,
      })),
    ];
    console.log(formattedOptions);
    setOptions(formattedOptions);
  }, [items, labelField, includeNullOption]);

  return (
    <Dropdown
      options={options}
      selectedOptionKey={selectedOptionKey}
      buttonClassName={buttonClassName}
      dropdownClassName={dropdownClassName}
      itemClassName={itemClassName}
      itemHoverClassName={itemHoverClassName}
      itemSelectedClassName={itemSelectedClassName}
      onOptionChange={onOptionChange}
    />
  );
};

export default DropdownWithREST;
